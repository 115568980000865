<template>
  <!--
    A grid (coordinate system) with the center being the center of the SVG.
    180° every 10° a line is 18 lines
  -->
  <g class="svg-grid-wrapper">
    <!-- vertical lines -->
    <template v-for="index in getColumnArray()">
      <line
        :key="`svg-grid-v-${ index }`"
        :x1="getX(rowHeight * index)"
        :x2="getX(rowHeight * index)"
        class="svg-grid"
        :y1="getY(-height / 2)"
        :y2="getY(height / 2)"
      />
    </template>
    <!-- horizontal lines -->
    <template v-for="index in getRowArray()">
      <line
        :key="`svg-grid-h-${ index }`"
        :y2="getY(columnWidth * index)"
        :y1="getY(columnWidth * index)"
        class="svg-grid"
        :x1="getX(-width / 2)"
        :x2="getX(width / 2)"
      />
    </template>
    <!-- center lines -->
    <template v-if="center">
      <line
        class="svg-grid svg-grid-strong"
        :x1="getX(-width / 2)"
        :y1="getY(0)"
        :x2="getX(width / 2)"
        :y2="getY(0)"
      />
      <line
        class="svg-grid svg-grid-strong"
        :x1="getX(0)"
        :y1="getY(height / 2)"
        :x2="getX(0)"
        :y2="getY(-height / 2)"
      />
    </template>
    <!-- bottom left lines -->
    <template v-else>
      <line
        class="svg-grid svg-grid-strong"
        :x1="getX(-width / 2)"
        :y1="getY(height / 2)"
        :x2="getX(width / 2)"
        :y2="getY(height / 2)"
      />
      <line
        class="svg-grid svg-grid-strong"
        :x1="getX(-width / 2)"
        :y1="getY(height / 2)"
        :x2="getX(-width / 2)"
        :y2="getY(-height / 2)"
      />
    </template>
  </g>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    columns: {
      type: Number,
      required: true,
    },
    rows: {
      type: Number,
      required: true,
    },
    center: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    anchorIsCenter: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    }
  },
  data () {
    return {
      columnWidth: 0,
      rowHeight: 0,
    }
  },
  mounted () {
    this.columnWidth = this.width / this.columns;
    this.rowHeight = this.height / this.rows;
  },
  methods: {
    calculatedColumnWidth (index) {
      return this.columnWidth * index;
    },
    calculatedRowHeight (index) {
      return this.rowHeight * index;
    },
    getRowArray () {
      let array = [];
      let halfRows = Math.floor(this.rows / 2);
      for (let i = -halfRows; i <= halfRows; i++) {
        array.push(i);
      }
      return array;
    },
    getColumnArray () {
      let array = [];
      let halfColumns = Math.floor(this.columns / 2);
      for (let i = -halfColumns; i <= halfColumns; i++) {
        array.push(i);
      }
      return array;
    },
    getX (x) {
      return this.anchorIsCenter ? x : x + this.width / 2;
    },
    getY (y) {
      return this.anchorIsCenter ? y : y + this.height / 2;
    }
  }
}
</script>

<style scoped>
.svg-grid {
  /* stroke: rgba(194, 185, 185, 0.932); */
  stroke: rgba(26, 17, 17, 0.932);
  stroke-width: 0.1pt;
}
.svg-grid-strong {
  stroke: rgb(22, 21, 21, 0.932);
  stroke-width: 0.5pt;
  
}
</style>
